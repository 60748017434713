export const environment = {
    production: false,
    env: 'staging',
    domainFrontend: 'https://staging-frontend.hicareer.com',
    domain: 'https://staging-backend.hicareer.com',
    api_prefix: '/api/v1',
    chat_server: 'https://cs.hicareer.com'
};

// NOTE stripePriceId in staging is the same as in development (so we can test in staging mode against Stripes sandbox)

export const businessPlans = {
    'sv': [
        {
            'annually': [
                { name: 'Business Premium 30', type: 'bp30', period: 'annually', stripePriceId: 'price_1L58anH4kQOk6NUADXchbdGc', pricePerMonth: 1611, pricePerYear: 19332, currency: 'SEK', discount: 10, selected: true },
                { name: 'Business Premium 100', type: 'bp100', period: 'annually', stripePriceId: 'price_1L58ccH4kQOk6NUA5oaKamaT', pricePerMonth: 2691, pricePerYear: 32292, currency: 'SEK', discount: 10, selected: true },
                { name: 'Business Premium 200', type: 'bp200', period: 'annually', stripePriceId: 'price_1L58eyH4kQOk6NUAoMp6N0fx', pricePerMonth: 4491, pricePerYear: 53892, currency: 'SEK', discount: 10, selected: true },
            ],
            'monthly': [
                { name: 'Business Premium', type: 'bp', period: 'monthly', stripePriceId: 'price_1M58WUH4kQOk6NUAPztZNTqg', pricePerMonth: 79, pricePerYear: 79*12, currency: 'EUR', discount: 0, selected: true },
                { name: 'Business Premium 30', type: 'bp30', period: 'monthly', stripePriceId: 'price_1L585XH4kQOk6NUANgjaNKk5', pricePerMonth: 1790, pricePerYear: 1790*12, currency: 'SEK', discount: 0, selected: true },
                { name: 'Business Premium 100', type: 'bp100', period: 'monthly', stripePriceId: 'price_1L58D6H4kQOk6NUAHaNE3dA5', pricePerMonth: 2990, pricePerYear: 2990*12, currency: 'SEK', discount: 0, selected: true },
                { name: 'Business Premium 200', type: 'bp200', period: 'monthly', stripePriceId: 'price_1L58DjH4kQOk6NUAeWrqSPSd', pricePerMonth: 4990, pricePerYear: 4990*12, currency: 'SEK', discount: 0, selected: true },
            ]
        }
    ],
    'en': [
        {
            'annually': [
                { name: 'Business Premium 30', type: 'bp30', period: 'annually', stripePriceId: 'price_1L58bXH4kQOk6NUAf3O6SvL2', pricePerMonth: 161, pricePerYear: 1933, currency: 'USD', discount: 10, selected: false },
                { name: 'Business Premium 100', type: 'bp100', period: 'annually', stripePriceId: 'price_1L58ddH4kQOk6NUAJf97iz5o', pricePerMonth: 269, pricePerYear: 3229, currency: 'USD', discount: 10, selected: false },
                { name: 'Business Premium 200', type: 'bp200', period: 'annually', stripePriceId: 'price_1L58fGH4kQOk6NUAOoqdBdbM', pricePerMonth: 449, pricePerYear: 5389, currency: 'USD', discount: 10, selected: false },
            ],
            'monthly': [
                { name: 'Business Premium', type: 'bp', period: 'monthly', stripePriceId: 'price_1M58WUH4kQOk6NUAPztZNTqg', pricePerMonth: 79, pricePerYear: 79*12, currency: 'EUR', discount: 0, selected: true },
                { name: 'Business Premium 30', type: 'bp30', period: 'monthly', stripePriceId: 'price_1L0nopH4kQOk6NUA4PafExDV', pricePerMonth: 179, pricePerYear: 179*12, currency: 'USD', discount: 0, selected: false },
                { name: 'Business Premium 100', type: 'bp100', period: 'monthly', stripePriceId: 'price_1L58D6H4kQOk6NUAmw8PHh4h', pricePerMonth: 299, pricePerYear: 299*12, currency: 'USD', discount: 0, selected: false },
                { name: 'Business Premium 200', type: 'bp200', period: 'monthly', stripePriceId: 'price_1L58E0H4kQOk6NUAdDhsccxT', pricePerMonth: 499, pricePerYear: 499*12, currency: 'USD', discount: 0, selected: false },
            ]
        }
        //{ period: 'annually', stripePriceId: 'price_1IyVYFH4kQOk6NUAJxgorvrm',  pricePerMonth: 19.99, pricePerYear: 19.99*12, currency: 'USD', discount: 20, selected: true  },
        //{ period: 'monthly', stripePriceId: 'price_1IyVYiH4kQOk6NUAzuYjLUJe', pricePerMonth: 9.99, pricePerYear: 9.99*12, currency: 'USD', discount: 0, selected: false },
    ]
}
